import './App.css';
// import 'antd/dist/antd.css';

import './style/custom-antd.css'
import Firstpg from './components/Firstpg.js'
import Client from './components/Client';
import Features from './components/Features';
// import Contact from './components/Contact'
import NavFooter from './components/NavFooter';
import FooterMain from './components/FooterMain';


function App() {
  return (
    <div className="app">
      
      <Firstpg />
      <Client />
      <Features />
      <NavFooter />
      <FooterMain />
      
    </div>
  );
}

export default App;
