import React from 'react'
import './Client.css'



function Client() {
    return (
        //remove id later
        <div className= "clients" id = "client">

            <div className = "clients__heading"> About Us</div>
            
            <div className= "clients__description"> We understand the amount of thought &amp; effort that goes into
            buying ‘the perfect gift’. Whether it’s a birthday occasion of your special
            someone or you want to win over your employee by appreciating them with a
            fabulous gift, Buyzone is here to help you with the same in an unbeatable price
            range.

             </div>
            
        </div>
    )
}

export default Client
