import React, { useState } from 'react';
import { Anchor, Drawer, Button } from 'antd';
// import {MenuOutlined} from '@ant-design/icons'
import {Menu} from 'antd';
import './AppHeader.css';
import hamburger from '../images/logos/menu-icon.png'
import logo from '../images/logos/desktop-logo.png';
import logoMobile from '../images/logos/logo.png'


function AppHeader() {
      const [visible, setVisible] = useState(false);
      const showDrawer = () => {
      setVisible(true);
      };
      const onClose = () => {
      setVisible(false);
      };

      
        function scrollAbout(){
          let divT = document.querySelector(".clients");
          divT.scrollIntoView({behavior: 'smooth'});
        }

        function scrollVoucher(){
          let features = document.querySelector("#vouchers");
          features.scrollIntoView({behavior: 'smooth'});
        }
      
        function scrollProducts(){
          let products = document.querySelector("#products");
          products.scrollIntoView({behavior: 'smooth'});
        }

        function scrollDigital(){
          let digitalStores = document.querySelector("#digitalStores");
          digitalStores.scrollIntoView({behavior: 'smooth'});
        }




    return (
      
      <div className = "container-fluid">
      <div className = "header"> 
      
      <div className="logo">
      
       {/* <img src= {logo1} srcset= {`${logo2} 2x, ${logo3} 3x`} />  */}
       <img className = "logo-desktop" src = {logo} />
       <img className = "logo-mobile" src = {logoMobile} />
      </div>

      <div className = "mobileHidden"> 
        <div className = "menu"> 
      
      <Menu mode="horizontal">
        
        <Menu.Item key="home" className= "menuItem" >   Home  </Menu.Item>
        <Menu.Item key="whatwedo" className = "menuItem" onClick = {scrollAbout}> About us </Menu.Item>
        <Menu.Item key="ourteam" className = "menuItem" onClick = {scrollVoucher}> E-Vouchers</Menu.Item>
        <Menu.Item key="casestudies" className = "menuItem" onClick = {scrollProducts}> Products</Menu.Item>
        <Menu.Item key="contactus" className = "menuItem" onClick = {scrollDigital}> Digital Stores</Menu.Item>
      </Menu>
        </div>
      </div>

        <div className = "mobileVisible">  
        {/* <Button type="primary" onClick={showDrawer}> */}
          {/* <MenuOutlined /> */}
          <img src = {hamburger} onClick={showDrawer} />
        {/* </Button> */}
      <Drawer
        
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
      
      <Menu>
        <Menu.Item key="home" className= "menuItem" > Home </Menu.Item>
        <Menu.Item key="whatwedo" className = "menuItem" onClick = {scrollAbout}> About us</Menu.Item>
        <Menu.Item key="ourteam" className = "menuItem" onClick = {scrollVoucher}> E-Vouchers</Menu.Item>
        <Menu.Item key="casestudies" className = "menuItem" onClick = {scrollProducts}> Products</Menu.Item>
        <Menu.Item key="contactus" className = "menuItem" onClick = {scrollDigital}> Digital Stores</Menu.Item>
      </Menu>
        
      </Drawer>
      </div>
      </div>
      </div>
    )
}

export default AppHeader;
