import React from 'react'
import AppHeader from './AppHeader'
import AppHome from './AppHome.js'
import background1 from '../images/background/path33.png';
import background2 from '../images/background/path33@2x.png';
import background3 from '../images/background/path33@3x.png';

import { Layout } from 'antd';
const { Header, Content } = Layout;

function Firstpg() {
	return (
		<div className="firstpg">
			<div style={{
				background: `url(${background1})`,
				backgroundRepeat: 'no-repeat'
			}}>
				<AppHeader />
				<Content>
					<AppHome />
				</Content>
			</div>
		</div>
		// </Layout>
	)
}

export default Firstpg
