import React from 'react'
import './AppHome.css'
import { Button } from 'antd';

import homeImg1 from '../images/images3/group62.png';
import homeImg1Mobile from '../images/images3/group62-mobile.png';
// import homeImg2 from '../images/images3/group62@2x.png';
// import homeImg3 from '../images/images3/group62@3x.png';

// import imgTop1_1 from '../images/appHome/imgTop1/group19.png';
// import imgTop1_2 from '../images/appHome/imgTop1/group19@2x.png';
// import imgTop1_3 from '../images/appHome/imgTop1/group19@3x.png';

// import imgTop2_1 from '../images/appHome/imgTop2/group12.png';
// import imgTop2_2 from '../images/appHome/imgTop2/group12@2x.png';
// import imgTop2_3 from '../images/appHome/imgTop2/group12@3x.png';

// import imgBottom1_1 from '../images/appHome/imgBottom1/group20.png';
// import imgBottom1_2 from '../images/appHome/imgBottom1/group20@2x.png';
// import imgBottom1_3 from '../images/appHome/imgBottom1/group20@3x.png';

// import imgBottom2_1 from '../images/appHome/imgBottom2/group18.png';
// import imgBottom2_2 from '../images/appHome/imgBottom2/group18@2x.png';
// import imgBottom2_3 from '../images/appHome/imgBottom2/group18@3x.png';

import app_img1 from '../images/downloadLinks/appStore.png'
import app_mobile from '../images/downloadLinks/link-playbutton.png'

import play_img1 from '../images/downloadLinks/play.png'
import play_mobile from '../images/downloadLinks/link-appstore.png'

function AppHome() {
    return (
        <div className = "appHome">   
            <div className= "appHome__heading">
            
            <div className = "heading1"> E-Vouchers from over <span className = "highlight"> 250+ </span> brands  </div>

            <div className = "block1__imgHeading">  

            
            {/* <span className = "heading2"> and much more </span> <span className="heading2 heading3"> </span> <span className = "heading2">  </span> */}


            </div>
            

            <div className = "appDescription"> 
            <div className= "description"> Offering products and e-vouchers at the best prices. 
            </div>
          
            <span id = "description2" className= "description"> Shop Essentials, Personal care, Beauty, and much more.</span>
            </div>

        </div>
        
        

        <div className = "btnHolder">
        
            <a className = "downloadLink homeBtn" target="_blank" href="https://play.google.com/store/apps/details?id=in.co.brandzone.buyzone&hl=en&gl=US">
                <div className = "navFooter__logo"> 
                    <img src= {play_img1} /> 
                </div>
            </a>

            <a className = "downloadLink homeBtn" target="_blank" href="https://apps.apple.com/ph/app/buyzone/id1490421674?ign-mpt=uo%3D2">
                <div className = "navFooter__logo">
                    <img   src= {app_img1} />
                </div>
                {/* <img className = "download-mobile" src = {app_mobile} />    */}
            </a>
         
        </div>

         {/* <img src= {homeImg1} srcset= {`${homeImg2} 2x, ${homeImg3} 3x`} />  */}
         <img className = "homeImg" src = {homeImg1} />
         <img className = "homeImg-mobile" src = {homeImg1Mobile} />
         
         
        </div> 
    )
}

export default AppHome
