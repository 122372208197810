import React from 'react'
import './FooterMain.css'
import { Layout } from 'antd';

import facebook1 from '../images/footer/facebook/component212.png';
import facebook2 from '../images/footer/facebook/component212@2x.png';
import facebook3 from '../images/footer/facebook/component212@3x.png';

import instagram1 from '../images/footer/instagram/component222.png';
import instagram2 from '../images/footer/instagram/component222@2x.png';
import instagram3 from '../images/footer/instagram/component222@3x.png';


import youtube from '../images/footer/youtube/youtube.svg';

const {Footer } = Layout;



function FooterMain() {
    return (
        <div className = "footerMain">
            
            <Footer className = "defaultFooter">
            <div className = "footerMain__container">   
                <div className = "terms">
                    © 2020 Buyzone Pvt Ltd. All rights reserved.
                </div>
            

            <div className = "footerMain__logo">

            <a className = "socialLink" target="_blank" href="https://www.facebook.com/buyzone11/?ti=as">
            <img src= {facebook1} srcset= {`${facebook2} 2x, ${facebook3} 3x`} /> 
            </a>
            <a className = "socialLink" target="_blank" href="https://www.instagram.com/buyzone1/?utm_medium=copy_link">
            <img src= {instagram1} srcset= {`${instagram2} 2x, ${instagram3} 3x`} /> 
            </a>
          
            <a className = "socialLink" target="_blank" href="https://www.youtube.com/channel/UCU4hTNZ6KLaz4pMTJekdESQ">
            <img src= {youtube} id = "youtube" /> 
            </a>

            </div>
            </div>
            </Footer>

        </div>
    )
}

export default FooterMain
