import React from 'react'
import { Row, Col } from 'antd';
import './NavFooter.css'


import app_img1 from '../images/downloadLinks/appStore.png'
// import app_img2 from '../images/downloadLinks/appStore@2x.png'
// import app_img3 from '../images/downloadLinks/appStore@3x.png'

import play_img1 from '../images/downloadLinks/play.png'
// import play_img2 from '../images/downloadLinks/play@2x.png'
// import play_img3 from '../images/downloadLinks/play@3x.png'

import logo from '../images/logos/TextLogo.svg'


function NavFooter() {

    function scrollHome(){
        let home = document.querySelector(".header");
        home.scrollIntoView({behavior: 'smooth'});
    }

    function scrollAbout(){
        let about = document.querySelector(".clients");
        about.scrollIntoView({behavior: 'smooth'});
      }

      function scrollVoucher(){
        let features = document.querySelector("#vouchers");
        features.scrollIntoView({behavior: 'smooth'});
      }
    
      function scrollProducts(){
        let products = document.querySelector("#products");
        products.scrollIntoView({behavior: 'smooth'});
      }

      function scrollDigital(){
        let digitalStores = document.querySelector("#digitalStores");
        digitalStores.scrollIntoView({behavior: 'smooth'});
      }
    return (
        <div className = "navFooter">
            
            <Row>
                <Col xl={8} lg = {12} md = {24} sm = {24}   xs ={24}>    
                <div className = "navFooter__leftmost" > 
             
                    <img className = "navFooter__logo"  src= {logo} /> 

                <div className = "description__bottom"> 
                    <div className= "navFooter__description"> K-2/835 </div>
                    <div className= "navFooter__description"> Mahipalpur </div>
                    <div className= "navFooter__description"> New Delhi Delhi  </div>
                    <div className= "navFooter__description"> 110037 </div>
                    
                </div>
                </div>
                </Col>

                <Col  xl={8} lg = {12} md = {24} sm = {24} xs ={24} >    
                    <div className = "navFooter__middle">   
                    <div className = "navFooter23__heading">
                        Navigation
                    </div>

                    <div className= "navFooter23__subHeadings" onClick = {scrollHome}>
                        Home
                    </div>
                    
                    <div className= "navFooter23__subHeadings" onClick = {scrollAbout}>
                        About
                    </div>

                    <div className= "navFooter23__subHeadings" onClick = {scrollVoucher}>
                        E-Vouchers
                    </div>
                    
                    <div className= "navFooter23__subHeadings" onClick = {scrollProducts}>
                        Products
                    </div>

                    <div className= "navFooter23__subHeadings" onClick = {scrollDigital}>
                        Digital Stores
                    </div>
                    </div>
                </Col>


                <Col xl={8} lg = {12} md = {24} sm = {24} xs ={24}>    
                <div className = "downloadLinks">  

                <a className = "downloadLink" target="_blank" href="https://play.google.com/store/apps/details?id=in.co.brandzone.buyzone&hl=en&gl=US">
                <img className = "navFooter__logo"  src= {play_img1} /> 
                </a>

                <a className = "downloadLink" target="_blank" href="https://apps.apple.com/ph/app/buyzone/id1490421674?ign-mpt=uo%3D2">
                <img className = "navFooter__logo"  src= {app_img1} />   
                </a>
                </div>     
                </Col>

            </Row>

        </div>
    )
}

export default NavFooter
