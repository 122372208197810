import React from 'react'
import { Row, Col } from 'antd';
import './Features.css'

// import f1_image1 from '../images/features/img1/group75.png'
// import f1_image2 from '../images/features/img1/group75@2x.png'
// import f1_image3 from '../images/features/img1/group75@3x.png'

import f1_image1 from '../images/features/evoucher/group70.png'
import f1_image1Mobile from '../images/features/evoucher/group70-mobile.png'



import f2_image1 from '../images/features/products/group75.png'
import f2_image1Mobile from '../images/features/products/group75-mobile.png';

import f3_image1 from '../images/features/stores/group77.png'
import f3_image1Mobile from '../images/features/stores/group77-mobile.png'

// import f2_image1 from '../images/features/img2/group76.png'
// import f2_image2 from '../images/features/img2/group76@2x.png'
// import f2_image3 from '../images/features/img2/group76@3x.png'

// import f3_image1 from '../images/features/img3/group77.png'
// import f3_image2 from '../images/features/img3/group77@2x.png'
// import f3_image3 from '../images/features/img3/group77@3x.png'

// logo

import f1_logo1 from '../images/features/img1/f1_logo/group74.png'
import f1_logo2 from '../images/features/img1/f1_logo/group74@2x.png'
import f1_logo3 from '../images/features/img1/f1_logo/group74@3x.png'

import f2_logo1 from '../images/features/img2/f2_logo/group43.png'
import f2_logo2 from '../images/features/img2/f2_logo/group43@2x.png'
import f2_logo3 from '../images/features/img2/f2_logo/group43@3x.png'

import f3_logo1 from '../images/features/img3/f3_logo/group30.png'
import f3_logo2 from '../images/features/img3/f3_logo/group30@2x.png'
import f3_logo3 from '../images/features/img3/f3_logo/group30@3x.png'


function Features() {
	return (
		<div className="features">
			<Row gutter={[16, 16]} className="features__row" id="vouchers">
				{/* <div className = "container">   */}
				<Col xl={12} xs={24}>
					{/*------------ image---------  */}
					<div className="features__img">
						{/* <img className = "phone" src = {f2_image1} srcset= {`${f2_image2} 2x, ${f2_image3} 3x`} alt = "pic" /> */}
						<img className="phone" src={f1_image1} />
						<div className = "mobile-container">
							<img className="phone-mobile" src={f1_image1Mobile} />
						</div>
						{/* <img className="phone" src={f2_image1} srcset={`${f2_image2} 2x, ${f2_image3} 3x`} alt="pic" /> */}
					</div>
				</Col>
				<Col xl={12} xs={24} >
					<div className="features__ContentRight">
						<div className="heading-container">
							<img src={f1_logo1} srcset={`${f1_logo2} 2x, ${f1_logo3} 3x`} />
							<div className="features__heading">
								E-Vouchers
							</div>
						</div>
						<div className="features__content">
							<div className="features__description">
								We have curated a wide range of e-gift vouchers
								on more than 150 top national &amp; international brands. This service is available
								for both B2B and B2C, which means whether you are a corporate or an
								individual, we have exciting &amp; valuable offers for both of you.
								Our wide range of e-gift vouchers includes: fashion clothing, electronics, travel,
								beauty products, groceries and so much more.
							</div>
						</div>
					</div>
				</Col>
			</Row>
					<Row gutter={[16, 16]} className="features__row">
						<Col xl={12} xs={24} className="mobile-content">
							{/* -----------content ------------ */}
							<div className="features__ContentLeft" id="products">
								<div className="heading-container">
									<img src={f3_logo1} srcset={`${f3_logo2} 2x, ${f3_logo3} 3x`} />
									<div className="features__heading">
										Products
									</div>
								</div>
								<div className="features__content">
									<div className="features__description">
										There are numerous brands like Del Monte, Organic India, Cosco, Lakme,
										Sunsilk and many more who don’t have the format of e-gift vouchers. To give
										direct benefits to the consumers, BUYZONE has partnered with them for
										product sales where we provide customers with instant discounts while
										shopping online.
									</div>
								</div >
							</div >
						</Col >
						{/* <Col xl={12} xs={24} >
							<div className="features__img"></div>
						</Col> */}
						<Col xl={12} xs ={24} className = "mobile-img">
							{/* ------------image -------------- */}
							{/* <div className="features__img"></div> */}
							<div className = "features__img">   
								{/* <img src= {f1_image1} srcset= {`${f1_image2} 2x, ${f1_image3} 3x`} />  */}
								<img className = "phone" src = {f2_image1} />
								<div className = "mobile-container">
									<img className = "phone-mobile" src = {f2_image1Mobile} />
								</div>
								{/* <img src= {f1_image1} srcset= {`${f1_image2} 2x, ${f1_image3} 3x`} />  */}
							</div >
						</Col >
					</Row >
					<Row gutter={[16, 16]} className="features__row" id="digitalStores">
						<Col xl={12} xs={24} >
						{/* ------------image-----------  */}
							<div className="features__img">
								<img className = "phone" src = {f3_image1} />
								<div className = "mobile-container">
									<img className = "phone-mobile" src = {f3_image1Mobile} />
								</div>
							</div >
						</Col >
						<Col xl={12} xs={24} >
						{/* --------------content------------ */}
						<div className="features__ContentRight"> 
							<div className= "heading-container"> 
							<img src= {f2_logo1} srcset= {`${f2_logo2} 2x, ${f2_logo3} 3x`} /> 
							<div className = "features__heading">
								Digital Stores
							</div>
						</div>
						<div className = "features__content"> 
							<div className = "features__description">
								We all have an old school soul in us who wants to touch and feel the product
								before buying. Buyzone brings digital stores to your city. Explore the ‘Digital
								Store’ section on BUYZONE app and discover the nearest spot to your home.
								Our digital stores have huge inventory, therefore once you order products, you
								get instant home delivery.
							</div>
						</div>
					</div >
				</Col >
			</Row >
		</div >
	)
}

export default Features
